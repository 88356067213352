<template>
	<div id="main">
		<v-index>
			<el-breadcrumb>
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">智能排课</a></el-breadcrumb-item>
				<!-- <el-breadcrumb-item><a style="color: #000000;">行政班排课</a></el-breadcrumb-item> -->
			</el-breadcrumb>
      <v-weburl-iframe class="content" url="teacher/schedule/setting" />
		</v-index>
	</div>
</template>

<script>
import VWeburlIframe from "@/components/weburl-iframe";
import VIndex from "@/components/common";
export default {
  components: {VIndex, VWeburlIframe}
};
</script>

<style scoped lang="scss">
.content {
  margin-top: 10px;
}
</style>
